import { defineMessages } from 'react-intl'

export const globalMessages = defineMessages({
  cancel: {
    id: 'globalMessages.cancel',
    defaultMessage: 'Cancel',
  },
  loading: {
    id: 'globalMessages.loading',
    defaultMessage: 'Loading',
  },
  save: {
    id: 'globalMessages.save',
    defaultMessage: 'Save',
  },
  requestPasswordResetCompleted: {
    id: 'globalMessages.requestPasswordResetCompleted',
    defaultMessage: `An email with a reset password link has been sent to {recipient}, if this email belongs to a registered company administrator. Please check your inbox. {breakingLine} If you do not receive an email shortly, make sure you are using the email address connected to your administrator account.`,
  },
  genericError: {
    id: 'globalMessages.genericError',
    defaultMessage: 'Something went wrong. Please try again later.',
  },
  allPricesWithoutVAT: {
    id: 'globalMessages.allPricesWithoutVAT',
    defaultMessage: 'All prices are excluded VAT',
  },
})

export const firebaseErrorMessages = defineMessages({
  'auth/too-many-requests': {
    id: 'auth/too-many-requests',
    defaultMessage:
      'We have blocked all requests from this device due to unusual activity. Try again later.',
  },
  'auth/wrong-password': {
    id: 'auth/wrong-password',
    defaultMessage: 'Wrong password',
  },
  'auth/user-not-found': {
    id: 'auth/user-not-found',
    defaultMessage: 'User not found',
  },
  'auth/invalid-email': {
    id: 'auth/invalid-email',
    defaultMessage: 'Enter a valid email',
  },
  'auth/user-disabled': {
    id: 'auth/user-disabled',
    defaultMessage:
      'User has a pending invite for a company that must be accepted. Please check your e-mail.',
  },
})

export const administratorMessages = defineMessages({
  email: {
    id: 'administratorMessages.email',
    defaultMessage: 'Email',
  },
  name: {
    id: 'administratorMessages.name',
    defaultMessage: 'Name',
  },
  expiresAt: {
    id: 'expires',
    defaultMessage: 'Expires',
  },
})

export const validationMessages = defineMessages({
  EMAIL_REQUIRED: {
    id: 'validationMessages.emailRequired',
    defaultMessage: 'Email is required',
  },
  NAME_REQUIRED: {
    id: 'validationMessages.nameRequired',
    defaultMessage: 'Name is required',
  },
  ORGANIZATION_NUMBER_REQUIRED: {
    id: 'validationMessages.ORGANIZATION_NUMBER_REQUIRED',
    defaultMessage: 'Organization number is required',
  },
  SIRET_NUMBER_REQUIRED: {
    id: 'modules.Registration.CompanyStep.SIRET_NR_REQUIRED',
    defaultMessage: 'SIRET number is required',
  },
  SIRET_NUMBER_INVALID: {
    id: 'modules.Registration.CompanyStep.SIRET_NR_INVALID',
    defaultMessage: 'SIRET number is invalid',
  },
  ADDRESS_REQUIRED: {
    id: 'validationMessages.ADDRESS_REQUIRED',
    defaultMessage: 'Address is required',
  },
  ZIP_CODE_REQUIRED: {
    id: 'validationMessages.ZIP_CODE_REQUIRED',
    defaultMessage: 'Zip code is required',
  },
  CITY_REQUIRED: {
    id: 'validationMessages.CITY_REQUIRED',
    defaultMessage: 'City is required',
  },
  RECEIPT_EMAIL_REQUIRED: {
    id: 'validationMessages.RECEIPT_EMAIL_REQUIRED',
    defaultMessage: 'Receipt email is required',
  },
  EMAIL_INVALID: {
    id: 'validationMessages.emailInvalid',
    defaultMessage: 'Enter a valid email',
  },
})

export const pageTitles = defineMessages({
  forgotPassword: {
    id: 'pages.ForgotPassword.title',
    defaultMessage: 'Forgot password',
  },
  newPassword: {
    id: 'pages.ResetPassword.title',
    defaultMessage: 'Reset password',
  },
  receipts: { id: 'pages.Receipts.Index.Title', defaultMessage: 'Receipts' },
  receipt: { id: 'pages.Receipt.Index.Title', defaultMessage: 'Receipt' },
  valuecodesConfirmation: {
    id: 'pages.ValueCodes.Confirmation.Title',
    defaultMessage: 'Confirmation',
  },
  valuecodesCreate: {
    id: 'pages.ValueCodes.Create.Title',
    defaultMessage: 'Create',
  },
  valuecodesGroup: {
    id: 'pages.ValueCodes.Group.Title',
    defaultMessage: 'Voucher codes',
  },
  valuecodes: {
    id: 'pages.ValueCodes.Index.Title',
    defaultMessage: 'Voucher codes',
  },
  administrators: {
    id: 'pages.Administrators.Index.Title',
    defaultMessage: 'Administrators',
  },
  inviteAdministrator: {
    id: 'pages.Administrators.Invite.Title',
    defaultMessage: 'Invite administrator',
  },
})
