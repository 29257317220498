import React, { useState } from 'react'
import { Formik, Form, Field, FormikErrors, FieldProps } from 'formik'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import {
  Anchor,
  Text,
  LoadableButton,
  Input,
  FormField,
  Alert,
  Flex,
  Link,
  PasswordInput,
  Box,
} from '../../'
import { isEmail } from '../../../lib/util'
import { useTenantAuth } from '../../../lib/firebase'
import { useApolloClient } from '@apollo/react-hooks'
import { firebaseErrorMessages } from '../../../lib/messages'
import { FirebaseError } from 'firebase'

const messages = defineMessages({
  EMAIL_REQUIRED: {
    id: 'LoginForm.emailRequired',
    defaultMessage: 'Email is required',
  },
  EMAIL_INVALID: {
    id: 'LoginForm.emailInvalid',
    defaultMessage: 'Enter a valid email',
  },
  PASSWORD_REQUIRED: {
    id: 'LoginForm.passwordRequired',
    defaultMessage: 'Password is required',
  },
  emailLabel: {
    id: 'LoginForm.emailLabel',
    defaultMessage: 'Email',
  },
  passwordLabel: {
    id: 'LoginForm.passwordLabel',
    defaultMessage: 'Password',
  },
})

type Values = { email: string; password: string }

export function CredentialForm({ onSignedIn }: { onSignedIn: () => void }) {
  const intl = useIntl()
  const auth = useTenantAuth(null)
  const [signInError, setSignInError] = useState<FirebaseError | undefined>()

  return (
    <Formik<Values>
      initialValues={{
        email: '',
        password: '',
      }}
      validate={(values) => {
        let errors: FormikErrors<Values> = {}
        if (!values.email) {
          errors.email = intl.formatMessage(messages.EMAIL_REQUIRED)
        } else if (!isEmail(values.email)) {
          errors.email = intl.formatMessage(messages.EMAIL_INVALID)
        }

        if (!values.password) {
          errors.password = intl.formatMessage(messages.PASSWORD_REQUIRED)
        }

        return errors
      }}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true)
        setSignInError(undefined)
        try {
          await auth.signInWithEmailAndPassword(values.email, values.password)
          onSignedIn()
        } catch (err) {
          setSubmitting(false)
          setSignInError(err)
        } finally {
          setSubmitting(false)
        }
      }}
    >
      {({ isSubmitting }) => {
        return (
          <Form data-testid="LoginForm">
            {signInError && (
              <Alert variant="error" mb="xs">
                {firebaseErrorMessages[signInError.code] ? (
                  <FormattedMessage
                    {...firebaseErrorMessages[signInError.code]}
                  />
                ) : (
                  signInError.message
                )}
              </Alert>
            )}
            <Box as="fieldset" border={0} maxWidth="24rem" mx="auto" width={1}>
              <Field name="email">
                {({ field, form }: FieldProps<Values['email'], Values>) => {
                  const name = field.name as 'email'
                  const errorMessage = form.touched[name] && form.errors[name]
                  return (
                    <FormField
                      label={intl.formatMessage(messages.emailLabel)}
                      errorMessage={errorMessage}
                      wrapperProps={{ mb: 'xs' }}
                    >
                      <Input
                        alternative
                        id="email"
                        type="email"
                        autoComplete="username"
                        {...field}
                      />
                    </FormField>
                  )
                }}
              </Field>
              <Field name="password">
                {({ field, form }: FieldProps<Values['password'], Values>) => {
                  const name = field.name as 'password'
                  const errorMessage = form.touched[name] && form.errors[name]
                  return (
                    <FormField
                      label={intl.formatMessage(messages.passwordLabel)}
                      errorMessage={errorMessage}
                      wrapperProps={{ mb: 'md' }}
                    >
                      <PasswordInput
                        alternative
                        id="password"
                        autoComplete="current-password"
                        {...field}
                      />
                    </FormField>
                  )
                }}
              </Field>
              <Flex justifyContent="center" mb="sm">
                <LoadableButton
                  type="submit"
                  variant="primary"
                  shape="cool"
                  loading={isSubmitting}
                  responseState={signInError ? 'error' : 'success'}
                >
                  <FormattedMessage
                    id="LoginForm.submitButton"
                    defaultMessage="Log in"
                  />
                </LoadableButton>
              </Flex>
              <Text textAlign="center" mb="sm">
                <Link href="/forgot-password">
                  <Anchor>
                    <FormattedMessage
                      id="components.LoginForm.forgotPassword"
                      defaultMessage="Forgot my password"
                    />
                  </Anchor>
                </Link>
              </Text>
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}

export function LoginForm() {
  const client = useApolloClient()
  return (
    <>
      <Text as="legend" mb="sm" typoStyle="lg" textAlign="center">
        <FormattedMessage id="LoginForm.legend" defaultMessage="Log in" />
      </Text>
      <CredentialForm
        onSignedIn={() => {
          client.resetStore()
        }}
      />
    </>
  )
}
