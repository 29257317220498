import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Flex, Box, Text, GridCell, Anchor, Grid, H1 } from '../../components'
import OperationalStatus from '../../components/Layout/OperationalStatus'
import { IntercomHandler } from '../../components/Layout/Intercom'
import { ApolloError } from 'apollo-client'
import { AnchorButton } from '@urbaninfrastructure/react-ui-kit'
import { useTenantAuth } from '../../lib/firebase'
import { CredentialForm } from '../../components/Layout/LoginForm'
import { useApolloClient } from '@apollo/react-hooks'
import { useAuthState } from 'react-firebase-hooks/auth'

type Props = {
  error?: Error | ApolloError
  errorType:
    | 'pageNotFound'
    | 'coreError'
    | 'unknown'
    | 'companyAdministratorNotFound'
  statusCode: number
  intercomId: string | undefined
}

const RefreshMessage = () => (
  <Text as="p">
    <FormattedMessage
      id="modules.ErrorPage.RefreshMessage.message"
      defaultMessage="Sorry about that. Please try refreshing and contact us if the problem persist."
    />
  </Text>
)

const ErrorHeading = (props: any) => <H1 mb={4} {...props} />
const SystemError = ({ heading }: { heading: React.ReactNode }) => {
  return (
    <>
      <Text fontSize={60} mb="sm">
        😰
      </Text>
      <ErrorHeading>{heading}</ErrorHeading>
      <RefreshMessage />
    </>
  )
}
const PageNotFound = () => {
  return (
    <>
      <Text fontSize={60} mb="sm">
        🤔
      </Text>
      <ErrorHeading>
        <FormattedMessage
          id="modules.ErrorPage.PageNotFound.heading"
          defaultMessage="The page could not be found"
        />
      </ErrorHeading>
      <FormattedMessage
        id="modules.ErrorPage.PageNotFound.text"
        defaultMessage="Check that the address entered is correct, or go to the {frontpageLink}"
        values={{
          frontpageLink: (
            <Anchor
              color="text"
              onClick={(e) => {
                e.preventDefault()
                document.location.replace('/')
              }}
            >
              <FormattedMessage
                id="modules.ErrorPage.PageNotFound.frontpage"
                defaultMessage="front page"
              />
            </Anchor>
          ),
        }}
      />
    </>
  )
}
const FailedToFetch = () => {
  return (
    <SystemError
      heading={
        <FormattedMessage
          id="modules.ErrorPage.FailedToFetch.heading"
          defaultMessage="Could not load content"
        />
      }
    />
  )
}
const CompanyAdministratorNotFound = () => {
  const auth = useTenantAuth(null)
  const [user] = useAuthState(auth)
  const client = useApolloClient()
  const [requiresCredentials, setRequiresCredentials] = useState(false)

  if (!user) {
    return null
  }

  async function deleteUser(u: firebase.User) {
    await u.delete()
    await client.resetStore()
  }

  return (
    <>
      {requiresCredentials && (
        <div>
          <div>Please re-authenticate to delete your account.</div>
          <CredentialForm
            onSignedIn={() => {
              deleteUser(user)
            }}
          />
        </div>
      )}
      {!requiresCredentials && (
        <>
          <Text as="p">
            Ouch, we could not find a company connected to your account.
          </Text>
          <Text as="p">
            To resolve this,{' '}
            <AnchorButton
              onClick={async () => {
                await auth.signOut()
                await client.resetStore()
              }}
            >
              log out
            </AnchorButton>{' '}
            and try again, or{' '}
            <AnchorButton
              onClick={async () => {
                try {
                  await deleteUser(user)
                } catch (err) {
                  if (err.code === 'auth/requires-recent-login') {
                    setRequiresCredentials(true)
                    return
                  }
                  throw err
                }
              }}
            >
              delete your account
            </AnchorButton>{' '}
            and register a new company.
          </Text>
        </>
      )}
    </>
  )
}
const UnknownError = () => {
  return (
    <SystemError
      heading={
        <FormattedMessage
          id="modules.ErrorPage.UnknownError.heading"
          defaultMessage="An unknown error has occured"
        />
      }
    />
  )
}
const ErrorPage = ({ errorType, intercomId }: Props) => {
  let component: React.ReactNode | null = null
  switch (errorType) {
    case 'coreError': {
      component = <FailedToFetch />
      break
    }
    case 'pageNotFound': {
      component = <PageNotFound />
      break
    }
    case 'companyAdministratorNotFound': {
      component = <CompanyAdministratorNotFound />
      break
    }
    default: {
      component = <UnknownError />
    }
  }
  return (
    <IntercomHandler
      intercomSettings={intercomId ? { app_id: intercomId } : null}
    >
      {({ show }) => {
        return (
          <Flex
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            minHeight="100vh"
            column
          >
            <Box mb={4}>{component}</Box>
            <Text small>
              <Grid>
                <GridCell>
                  <OperationalStatus />
                </GridCell>
                {intercomId && (
                  <GridCell>
                    <Anchor
                      onClick={(event) => {
                        event.preventDefault()
                        show()
                      }}
                      color="text"
                    >
                      <FormattedMessage
                        id="modules.ErrorPage.footer.contactUs"
                        defaultMessage="Contact us"
                      />
                    </Anchor>
                  </GridCell>
                )}
              </Grid>
            </Text>
          </Flex>
        )
      }}
    </IntercomHandler>
  )
}
ErrorPage.defaultProps = {
  statusCode: 500,
  errorType: 'unknown',
}
export default ErrorPage
