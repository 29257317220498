import firebaseApp, { FirebaseError } from 'firebase/app'
import 'firebase/auth'
import { useCallback, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { LanguageCode } from '../core-types'
import { FirebaseConfig } from '../types'
import { useSettingsQuery } from './core'
import { useSystemConfig } from './use-system-config'
import * as Sentry from '@sentry/browser'

function getFirebase(config: FirebaseConfig) {
  if (!firebaseApp.apps.length) {
    firebaseApp.initializeApp(config)
  }
  return firebaseApp
}

export function getAuth(
  tenantId: string,
  languageCode: LanguageCode | null,
  firebaseConfig: FirebaseConfig
) {
  const firebase = getFirebase(firebaseConfig)
  const auth = firebase.auth()
  auth.tenantId = tenantId
  auth.languageCode = languageCode
  return auth
}

export function useTenantAuth(
  languageCode: LanguageCode | null
): firebase.auth.Auth {
  const {
    businessFirebaseTenantId,
    defaultLanguageCode,
    firebaseConfig,
  } = useSystemConfig()
  if (!businessFirebaseTenantId) {
    throw new Error('systemConfig is missing businessFirebaseTenantId')
  }
  return getAuth(
    businessFirebaseTenantId,
    languageCode || defaultLanguageCode,
    firebaseConfig
  )
}

export function useResetPassword(): {
  loading: boolean
  called: boolean
  error?: FirebaseError
  sendPasswordResetEmail: (email: string) => Promise<void>
} {
  const { data } = useSettingsQuery()
  const [loading, setLoading] = useState(false)
  const [called, setCalled] = useState(false)
  const [error, setError] = useState<FirebaseError | undefined>()

  const auth = useTenantAuth(data?.me?.preferredLanguageCode || null)

  const system = data?.system

  if (!system) {
    throw new Error('System not loaded')
  }

  const { businessUrl } = system

  const sendPasswordResetEmail = useCallback(
    async (email: string) => {
      setError(undefined)
      setLoading(true)
      setCalled(true)
      try {
        await auth.sendPasswordResetEmail(email, {
          url: businessUrl,
        })
      } catch (err) {
        Sentry.captureException(err)
        setError(err)
      } finally {
        setLoading(false)
      }
    },
    [businessUrl]
  )

  return { sendPasswordResetEmail, loading, error, called }
}

export function useLoadedUser(): firebase.User {
  const { data } = useSettingsQuery()
  const auth = useTenantAuth(data?.me?.preferredLanguageCode || null)
  const [user] = useAuthState(auth)
  if (!user) {
    throw new Error(
      'useLoadedUser can only be used when the user have been loaded'
    )
  }
  return user
}
