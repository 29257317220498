import React from 'react'
import Router from 'next/router'
import isEqual from 'lodash/isEqual'
import { SettingsQuery } from '../../lib/core'

type Props = {
  intercomSettings: any
  children?: (props: { show: () => void }) => React.ReactElement
}

Router.events.on('routeChangeComplete', () => {
  if (window.Intercom) {
    window.Intercom('update')
  }
})

export class IntercomHandler extends React.Component<Props> {
  componentDidMount() {
    let interval
    // Wait for Intercom to boot (max 10 seconds)
    const timeout = setTimeout(() => interval && clearInterval(interval), 10000)
    interval = setInterval(() => {
      if (window.Intercom && window.Intercom.booted) {
        const { intercomSettings } = this.props
        if (intercomSettings) {
          window.Intercom('boot', intercomSettings)
        }
        interval && clearInterval(interval)
        clearTimeout(timeout)
      }
    }, 100)
  }
  componentDidUpdate(prevProps: Props) {
    const { intercomSettings } = this.props
    const { intercomSettings: prevIntercomSettings } = prevProps

    // if there have been a user_id, and its not there anymore, assume the user logged out
    if (
      prevIntercomSettings &&
      prevIntercomSettings.user_id &&
      intercomSettings &&
      !intercomSettings.user_id
    ) {
      window.Intercom('shutdown')
      window.Intercom('boot', intercomSettings)
      return
    }

    const shouldUpdate = !isEqual(intercomSettings, prevIntercomSettings)
    if (shouldUpdate) {
      window.Intercom('update', intercomSettings)
    }
  }

  show = () => {
    if (!window.Intercom) {
      return
    }
    window.Intercom('show')
  }

  render() {
    if (!this.props.children) {
      return null
    }
    return this.props.children({ show: this.show })
  }
}

export default function Intercom({ hideLauncher }: { hideLauncher?: boolean }) {
  return (
    <SettingsQuery>
      {({ data, loading }) => {
        if (loading || !data || !data.intercomSettings) {
          return null
        }

        const intercomSettings = {
          ...data.intercomSettings,
          hide_default_launcher: !!hideLauncher,
        }
        return <IntercomHandler intercomSettings={intercomSettings} />
      }}
    </SettingsQuery>
  )
}
